import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    return (
        <>
            <div>
                <div id="header" /> {/* Header'ı yüklemek için */}
                <div className="top-bar">
                    <div className="title-container">
                    <a href="sozluk.html" className="title-link">
                        <h1 className="title">Türkçes</h1>
                    </a>
                    <div className="subtitle">
                        Yabancılar için Öğrenci Sözlüğü
                    </div>
                    </div>
                    <div className="user-actions">
                    <button className="action-button">ÜYE OL</button>
                    <Link to="/Login">
                        <button className="action-button">GİRİŞ</button>
                    </Link>
                    </div>
                </div>
                <div className="header-container">
                    <div className="search-bar">
                    <div className="custom-select-wrapper">
                        <div id="language-select" className="custom-select" data-selected-value="turkce">
                        <div className="custom-select-trigger">
                            <span>Türkçe</span>
                            <div className="arrow" />
                        </div>
                        <div className="custom-options">
                            <div className="custom-option selected" data-value="turkce">Türkçe</div>
                            <div className="custom-option" data-value="turkce-ingilizce">Türkçe-İngilizce</div>
                            <div className="custom-option" data-value="turkce-rusca">Türkçe-Rusça</div>
                            <div className="custom-option" data-value="turkce-hırvatca">Türkçe-Hırvatça</div>
                            <div className="custom-option" data-value="turkce-arapca">Türkçe-Arapça</div>
                        </div>
                        </div>
                    </div>
                    <div className="search-input-wrapper">
                        {/* Türkçe Karakterler Popup */}
                        <div className="turkce-karakterler-popup">
                        <div className="turkce-karakterler">
                            <button className="turkce-karakter">ç</button>
                            <button className="turkce-karakter">ğ</button>
                            <button className="turkce-karakter">ı</button>
                            <button className="turkce-karakter">ö</button>
                            <button className="turkce-karakter">ş</button>
                            <button className="turkce-karakter">ü</button>
                        </div>
                        </div>
                        {/* Arama Kutusu ve İkonlar */}
                        <input type="text" id="search-input" placeholder="Kelime ara..." aria-label="Arama" autoComplete="off" />
                        <div className="close-icon" title="Temizle" />
                        <div className="keyboard-icon" title="Türkçe Karakterler" />
                        <div className="search-icon" title="Ara" />
                        {/* Öneri Kutusu */}
                        <div className="suggestions-container">
                        <ul />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
