import { Breadcrumb, Button, Input, Select } from "antd";
import PanelPageTitle from "../../../components/PanelPageTitle";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill'in varsayılan stil dosyası
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { faFileMedical } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

const AddMeaning = () =>{

    const { headWordId } = useParams();
    
    return(
        <>
            <PanelPageTitle icon={faFileMedical} bg="bg-[#3498DB]" title="Anlam Ekle"/>

            <Breadcrumb
                    className="mt-4"
                    items={[
                        {
                            title: <a href="/manager/head-word">Madde Başı Listesi</a>,
                        },
                        {
                            title: <a href={"/manager/meaning/"+headWordId}>Anlam Listesi</a>,
                        },
                        {
                            title: 'Anlam Ekle',
                        },
                    ]}
                />

            <div className="mt-5">

                <div className="bg-gray-100 p-7 rounded-lg mb-3">
                    <span>Anlam Sırası * : </span>
                    <Input className='mt-3 h-[40px]' placeholder="0"  />
                </div>
            
                <div className="bg-gray-100 p-7 rounded-lg mb-3">
                    <span>Sözcüğün Anlamı * : </span>
                    <ReactQuill
                    className="mt-3 h-[200px] mb-[40px] !rounded-lg"
                    placeholder="Metninizi buraya yazın..."
                    modules={{
                    toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike"], // Kalın, italik, altı çizili ve üstü çizili
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link"],
                        ["clean"],
                    ],
                    }}
                    formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "list",
                    "bullet",
                    "link",
                    ]}
                    />                
                </div>

                <div className="bg-gray-100 p-7 rounded-lg mb-3">
                    <span>Yabancı Dil : </span>
                    
                </div>

                <div className="bg-gray-100 p-7 rounded-lg mb-3">
                    <span>Sözcük Türü * : </span>
                    <Select
                    placeholder="Sözcük Türünü Seçiniz"
                    className='w-full mt-3 h-[40px]'
                    options={[
                        { value: 'İsim', label: 'İsim' },
                        { value: 'Sıfat', label: 'Sıfat' },
                        { value: 'Fiil', label: 'Fiil' },
                        { value: 'Belirgeç', label: 'Belirgeç' },
                        { value: 'Bağlaç', label: 'Bağlaç' },
                    ]}
                    />
                </div>

                <div className="bg-gray-100 p-7 rounded-lg mb-3">
                    <span>Dil Seviyesi * : </span>
                    <Select
                    placeholder="Dil Seviyesini Seçiniz"
                    className='w-full mt-3 h-[40px]'
                    options={[
                        { value: 'A1', label: 'A1' },
                        { value: 'A2', label: 'A2' },
                    ]}
                    />
                </div>

                <div className="bg-gray-100 p-7 rounded-lg mb-3">
                    <span>Örnek Görseller : </span>
                    
                </div>

                <div className="bg-gray-100 p-7 rounded-lg mb-3">
                    <span>Örnek Cümleler :</span>
                    <div className="flex gap-2 items-center">

                        <ReactQuill
                        className="mt-3 w-full h-[100px] mb-[40px] !rounded-lg"
                        placeholder="Metninizi buraya yazın..."
                        modules={{
                        toolbar: [
                            [{ header: [1, 2, false] }],
                            ["bold", "italic", "underline", "strike"], // Kalın, italik, altı çizili ve üstü çizili
                            [{ list: "ordered" }, { list: "bullet" }],
                            ["link"],
                            ["clean"],
                        ],
                        }}
                        formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "list",
                        "bullet",
                        "link",
                        ]}
                        />  

                        <Button type="primary"  className="bg-gray-500 hover:!bg-gray-400 mt-14 h-[100px] !w-[40px]" icon={<PlusOutlined />} />

                    </div>

                    
                </div>

                
                <div className="p-7 mb-3 flex justify-end  gap-3">
                    <Button type="primary"  className="bg-red-500 hover:!bg-red-400 h-[40px]" icon={<CloseOutlined />} >
                        İptal
                    </Button>
                    <Button type="primary"  className="bg-green-500 hover:!bg-green-400 h-[40px]" icon={<CheckOutlined />} >
                        Kaydet
                    </Button>
                </div>

            </div>

        </>
    );
}

export default AddMeaning;