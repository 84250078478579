import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SearchOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { faEdit, faFontAwesome, faPlusCircle, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Button , Tooltip, Popconfirm} from "antd";
import AddSubTematicModal from "./AddSubTematicModal";

const TematicTable = (props:any) =>{


    const columns = [
        {
            title: '#',
            width: 150,
            dataIndex: 'key',
            render: (text: any) => <span className="font-bold">{text}</span>,
        },
        {
            title: 'Fotoğraf',
            dataIndex: 'imgUrl',
        },
        {
            title: 'Tematik Adı',
            dataIndex: 'name',
        },
        {
            title: 'Sıralama',
            dataIndex: 'orderCode',
        },
        {
            title: '',
            width: 350,
            dataIndex: 'action',
            render: (action: { id: string; name: string }) => (
                <>
                    <Tooltip title="Güncelle">
                        <Button type="primary" className="bg-yellow-500 hover:!bg-yellow-400">
                            <FontAwesomeIcon icon={faEdit} size="1x"  />
                        </Button>
                    </Tooltip>
                    
                    <Tooltip title="Alt Tematik Ekle">
                        <>
                            <AddSubTematicModal parentId={action.id} parentName={action.name}/>
                        </>
                    </Tooltip>

                    <Popconfirm
                        title="Tematiği silmek istiyor musunuz?"
                        okText="Sil"
                        
                        cancelText="İptal"
                    >
                        <Tooltip title="Sil">
                            <Button type="primary" className="bg-red-500 hover:!bg-red-400">
                                <FontAwesomeIcon icon={faTrashAlt} size="1x"  />
                            </Button>
                        </Tooltip>
                    </Popconfirm>

                   
                </>
                    
            ),
        },

        
    ];

    return(
        <>
            <Table dataSource={props.dataSource} columns={columns} locale={{ emptyText: <span className="text-red-400 font-bold">Madde başı bulunamadı !</span>}}  />
        </>
    );

}

export default TematicTable;