import './Home.css'

const Home = ()=>{
    return(
        <>
          {/* Ana İçerik */}
<main role="main" id="main-content">
  {/* Seçenekler Bölümü */}
  <div id="options-section">
    <a href="daha-fazla-ornek.html" className="option-box">
      <img src="/img/daha-fazla-ornek.png" alt="Daha Fazla Örnek" />
      <h3>Daha Fazla Örnek</h3>
      <p>Öğrenmek istediğiniz sözcük ile ilgili çok sayıda örneği sunuyoruz.</p>
    </a>
    <a href="sozcuk-listeleri.html" className="option-box">
      <img src="/img/sozcuk-listeleri.png" alt="Sözcük Listeleri" />
      <h3>Sözcük Listeleri</h3>
      <p>A1 ve A2 düzeyi sözcüklerin listesine buradan ulaşabilirsiniz.</p>
    </a>
    <a href="tema.html" className="option-box">
      <img src="/img/tematik-listeler.png" alt="Tema Listeleri" />
      <h3>Tema Listeleri</h3>
      <p>Farklı temalarda sözcük listelerine göz atın.</p>
    </a>
    <a href="gorseller.html" className="option-box">
      <img src="/img/gorseller.png" alt="Görseller" />
      <h3>Görseller</h3>
      <p>Görseller ile sözcüklerin anlamlarını kolayca kavrayın.</p>
    </a>
  </div>
  {/* Arama Sonuçları ve Söz Ailesi */}
  <div id="result-wrapper" style={{display: 'block'}}>
    {/* Arama Sonuçları */}
    <div id="search-results" className="madde-basi-content">
      {/* Arama sonuçları burada dinamik olarak eklenecek */}
    </div>
    {/* Söz Ailesi */}
    <div id="soz-ailesi-ana-container" className="madde-basi-content">
      {/* Söz Ailesi bölümleri burada dinamik olarak eklenecek */}
    </div>
  </div>
</main>

        </>
    );

}

export default Home;
