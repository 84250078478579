import { Outlet } from "react-router-dom";

const EmptyLayout = ()=>{
    return(
        <>
            <main>
                <Outlet /> {/* Alt bileşenler burada render edilecek */}
            </main>
        </>
    );

}

export default EmptyLayout;