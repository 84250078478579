import axios from '../interceptors/AuthInterceptor';
import { IAddHeadWord } from '../models/headWord/IAddHeadWord';
import { IHeadWordIdRequest } from '../models/headWord/IHeadWordIdRequest';
import { IUpdateHeadWord } from '../models/headWord/IUpdateHeadWord';

const HeadWordService =()=> {

    const apiUrl = process.env.REACT_APP_API_URL


    const addHeadWord = async (data:IAddHeadWord) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/HeadWord/add-head-word`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const updateHeadWord = async (data:IUpdateHeadWord) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/HeadWord/update-head-word`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const getByIdHeadWord = async (data:IHeadWordIdRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/HeadWord/get-by-id-head-word`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const deleteHeadWord = async (data:IHeadWordIdRequest) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/HeadWord/delete-head-word`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const getHeadWord = async () => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/HeadWord/get-head-word`);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };


    return {
        addHeadWord,
        getHeadWord,
        deleteHeadWord,
        getByIdHeadWord,
        updateHeadWord,

    };


}

export default HeadWordService;
