const NotFoundPage = ()=>{
    return(
        <>
            Aradığınız sayfa bulunamadı !!!
        </>
    );

}

export default NotFoundPage;
