import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Popconfirm, Space } from "antd";
import { useEffect, useState } from "react";
import TematicService from "../../../../services/TematicService";
import Loading from "../../../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { ISubTematic } from "../../../../models/tematic/ISubTematic";

const AddSubTematicModal = (props:any) =>{

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoadingAddTematic, setIsLoadingAddTematic]= useState(false)
    const [isLoadingSubTematicList, setIsLoadingSubTematicList]= useState(false)
    const _tematicService = TematicService();
    const [fields, setFields] = useState<ISubTematic[]>([]);

    const getSubTematic = async ()=>{
        try { 

            setIsLoadingSubTematicList(true);
            var data = {
                tematicId : props.parentId
            }
            var response = await _tematicService.getSubTematic(data);
            
            if (response.statusCode == 200 ) {
          
                console.log('response: ', response);
                const newFields = response.data.map((item: any) => ({
                    name: item.name,
                    subOrderCode: item.subOrderCode,
                }));
        
                setFields((prevFields) => [...prevFields, ...newFields]);
                
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("getsubtematic :", err);
        }finally{
            setIsLoadingSubTematicList(false);

        }
        
    }



    // Yeni alan çifti eklemek için fonksiyon
    const addFields = () => {
      setFields([...fields, { name: "", subOrderCode: "" }]);
    };
  
    // Kullanıcı girişlerini güncellemek için fonksiyon
    const handleInputChange = (index: number, field: keyof ISubTematic, value: string) => {
      const updatedFields = [...fields];
      updatedFields[index][field] = value;
      setFields(updatedFields);
    };

    const showModal = () => {
        setIsModalOpen(true);
        getSubTematic()
    };

    const validatorTematic = (data:any)=>{
        let isValid = true;

        if (data.fields.length == 0) {
            message.error(`Alt tematik ekleyiniz !`);
            isValid = false;
            
        }

        data.fields.forEach((field:any, index:any) => {
            if (!field.name || field.name.trim() === "") {
                message.error(`Tematik adı boş geçilemez !`);
                isValid = false;
            }
    
            if (!field.subOrderCode || field.subOrderCode.trim() === "") {
                message.error(`Sıralama kodu boş geçilemez !`);
                isValid = false;
            }
        });
    
        return isValid;
        
    }

    const handleOk = async () => {
        try {

            var data ={
                parentId:props.parentId,
                fields : fields
            }
            var result =validatorTematic(data)
            if (result == false) {
                return
            }
            setIsLoadingAddTematic(true);
            var response = await _tematicService.addSubTematic(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Alt Tematik Eklendi")
                setIsModalOpen(false);
                setFields([])

                
                // callback'i çağır
                props.onTematicAdded();
                
                
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
            
        } catch (err) {
            console.log("AddTematicModal",err)
        }
        finally{
            setIsLoadingAddTematic(false);
            
        }
    };

    
    const handleCancel = () =>{

        setIsModalOpen(false)
        setFields([])

    }
    const deleteSubTematic = (index:any) =>{
        setFields((prevFields) => {
            // Diziyi filtreleyerek belirtilen index'i kaldır
            return prevFields.filter((_, i) => i !== index);
        });
        
    }
    
    return(
        <>

        <Button onClick={showModal} type="primary" className="bg-[#36786c] hover:!bg-[#285050] mr-5 ml-5">
            <FontAwesomeIcon icon={faPlusCircle} size="1x"  />
        </Button>

        <Modal title= {<span className="font-normal"> <span className="font-bold"> {props.parentName}</span> Tematiğine Alt Tematik Ekle</span> }
            okText="Kaydet"
            cancelText="İptal" 
            maskClosable={false} 
            okButtonProps={{ className: 'bg-green-500 text-white hover:!bg-green-300' }}  
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>


                <div className="mb-5">
                    <Button onClick={addFields} type="primary" 
                    className="w-full mt-5 bg-gray-500 hover:!bg-gray-400">
                            <FontAwesomeIcon icon={faPlus} size="1x"  />
                            Ekle
                    </Button>
                    <div>
                        {fields.map((field, index) => (
                        <div key={index} 
                            className="flex gap-3 mt-3">

                            <Input className='h-[40px] w-full' placeholder="Alt tematik adı" 
                                value={field.name} 
                                onChange={(e) => handleInputChange(index, "name", e.target.value)} />

                          
                            <Input className='h-[40px] w-[150px]' type='number' placeholder="Sıralama" 
                                value={field.subOrderCode}
                                onChange={(e) => handleInputChange(index, "subOrderCode", e.target.value)}
                                />

                            <Popconfirm
                                title="Alt tematiği silmek istiyor musunuz ?"
                                onConfirm={e=>{deleteSubTematic(index)}}
                                okText="Evet"
                                cancelText="Hayır"
                            >
                            
                                <Button type="primary" 
                                className="h-[40px] bg-red-500 hover:!bg-red-400">
                                    <FontAwesomeIcon icon={faTrashAlt} size="1x"  />
                                </Button>
                            </Popconfirm>

                            
                        </div>
                        ))}
                    </div>
                </div>
                    
                {
                    isLoadingAddTematic == true || isLoadingSubTematicList == true ? 
                    <Loading/>:<></>
                }
        </Modal>
        
        </>
    );
}

export default AddSubTematicModal;