
import { message } from 'antd';
import axios from 'axios';

// Token'ı her isteğe ekle
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axios.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
      if (error.response?.status === 401) {
        message.error("Oturumunuzun süresi doldu. Lütfen giriş yapınız.")
        window.location.href = "/login"; 
      }
      if (error.response?.status === 403) {
        message.error("Yetkiniz bulunmamaktadır!")
        window.location.href = "/unauthorized"; 
  }
    return Promise.reject(error);
  }
);

export default axios;
