import axios from 'axios';
import { ILogin } from '../models/login/ILogin';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

const AuthService =()=> {

  const apiUrl = process.env.REACT_APP_API_URL
  const navigate = useNavigate();

  // Giriş işlemi
    const login = async (data: ILogin) => {
      try { 
        
        /* ${process.env.REACT_APP_API_URL}  */
        const response = await axios.post(`${apiUrl}/Auth/auth-login`, data);
        return response.data;
      } 
    catch (error: any) {
        return error.response.data;
    }
  };

  

  // Çıkış işlemi
  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userData');
    message.error('Çıkış Yapıldı');
    navigate('/login');
  };


  return {
    login,
    logout,
  };


}

export default AuthService;
