import { Button, message } from "antd";
import PanelPageTitle from "../../../components/PanelPageTitle";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { faFileLines, faFileWord } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../components/Loading";
import HeadWordTable from "./components/HeadWordTable";
import { useEffect, useState } from "react";
import HeadWordService from "../../../services/HeadWordService";

const HeadWord = () =>{

    const [isLoadingHeadWordList, setIsLoadingHeadWordList]= useState(false)
    const [dataSource, setDataSource] = useState([]);
    const _headService = HeadWordService();

    useEffect(() =>{
        getHeadWord();        
    }, []) //? Boş dizi yalnızca ilk yüklendiğinde derlenir


    const getHeadWord = async ()=>{
        try { 

            setIsLoadingHeadWordList(true);
            var response = await _headService.getHeadWord();
            
            if (response.statusCode == 200 ) {
                const formattedData = response.data.map((item:any, index:any) => ({
                    key: index + 1, 
                    action:{
                        id: item.headWordId,
                        name: item.name,
                    },
                    name: item.name,
                    homophoneCode:item.homophoneCode,

                }));
                console.log('response: ', response);
                
                setDataSource(formattedData);
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("getHeadWord :", err);
        }finally{
            setIsLoadingHeadWordList(false);
        }
        
    }
    return(
        <>
            <PanelPageTitle icon={faFileLines} bg="bg-[#3498DB]" title="Madde Başı Listesi"/>


            <Link to="/manager/add-head-word">
                <Button type="primary"  className="bg-green-500 hover:!bg-green-400 mt-4" icon={<PlusOutlined />} >
                    Madde Başı Ekle
                </Button>
            </Link>

            {
                isLoadingHeadWordList ==true ?
                (<Loading/>):
                (
                    <div className="mt-5">
                        <HeadWordTable dataSource={dataSource} onHeadWordDeleted={getHeadWord}/>
                    </div>
                )
            }



        </>
    );
}
export default HeadWord;