import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SearchOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { faEdit, faTrashAlt, faTrashArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Button , Tooltip} from "antd";

const UserTable = (props:any) =>{


    const columns = [
        {
            title: '#',
            width: 50,
            dataIndex: 'key',
            render: (text: any) => <span className="font-bold">{text}</span>,
        },
        
        {
            title: 'Adı',
            dataIndex: 'name',
        },
        {
            title: 'Soyadı',
            dataIndex: 'surname',
        },
        {
            title: 'Kullanıcı Adı',
            dataIndex: 'username',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            render: (role:number) => (role === 0 ? 'Yönetici' : 'Çalışan'),
        },
        {
            title: 'Durumu',
            dataIndex: 'status',
            render: (status:number) => (status == 0 ? <CloseOutlined  style={{ color: 'red' }}  /> : <CheckOutlined  style={{ color: 'green' }}  />),
        },
        {
            title: '',
            width: 120,
            dataIndex: 'action',
            render: (id:string) => (
                <>
                    <Tooltip title="Güncelle">
                        <Button type="primary" className="bg-yellow-500 ml-1 mr-1 hover:!bg-yellow-400" icon={<FontAwesomeIcon icon={faEdit} size="1x"  />}  />
                    </Tooltip>
                    
                    <Tooltip title="Sil">
                        <Button type="primary" className="bg-red-500 hover:!bg-red-400" icon={<FontAwesomeIcon icon={faTrashAlt} size="1x"  />}  />
                    </Tooltip>


                    {/* <Tooltip title="Kullanıcı Atama">
                        <Button type="primary" className="bg-gray-500 hover:!bg-gray-400" icon={<UsergroupAddOutlined />}  />
                    </Tooltip> */}
                </>
                    
            ),
        },
        
    ];

    return(
        <>
            <Table dataSource={props.dataSource} columns={columns} />
        </>
    );

}

export default UserTable;