import axios from '../interceptors/AuthInterceptor';
import { IAddUser } from '../models/user/IAddUser';

const UserService =()=> { 
    
    const apiUrl = process.env.REACT_APP_API_URL

    const getTokenUser = async () => {  
        try { 
            /* ${process.env.REACT_APP_API_URL} */
            const response = await axios.get(`${apiUrl}/User/get-token-user`,);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const getUser = async () => {  
        try { 
            
            const response = await axios.get(`${apiUrl}/User/get-user`,);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const addUser = async (data:IAddUser) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/User/add-user`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    return {
        getTokenUser,
        getUser,
        addUser,
    };


}

export default UserService;
