import React, { useState } from 'react';
import { Button, Modal, Input, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import UserService from '../../../../services/UserService';
import { Select } from "antd";
import { IAddUser } from '../../../../models/user/IAddUser';

const AddUserModal = () =>{

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [username, setUsername] = useState("");
    const [mail, setMail] = useState("");
    const [gender, setGender] = useState("Cinsiyeti Seçiniz");
    const [role, setRole] = useState("Rolü Seçiniz");
    const _userService = UserService();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        try {



            var data:IAddUser ={
                name: name,
                surname:surname,
                username:username,
                mail:mail,
                gender:gender,
                role:role,
            }

            var result =validatorUser(data)
            if (result == false) {
                return
            }
            
            var response = await _userService.addUser(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Kullanıcı Eklendi")
                setIsModalOpen(false);
                setName("");

                
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
            
        } catch (err) {
            console.log("AddUserModal",err)
        }
    };

    const validatorUser = (data:IAddUser)=>{

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (data.name == "") {
            message.error("Ad boş geçilemez !")
            return false;
        }
        if (data.surname == "") {
            message.error("Soyad boş geçilemez !")
            return false;

        }
        if (data.username == "") {
            message.error("Kullanıcı adı boş geçilemez !")
            return false;

        }
        if (data.mail == "") {
            message.error("Mail boş geçilemez !")
            return false;
        }
        if (!emailRegex.test(data.mail)) {
            message.error("Geçersiz e-posta formatı !")
            return false;
        }
        if (data.gender == "Cinsiyeti Seçiniz") {
            message.error("Cinsiyet boş geçilemez !")
            return false;
        }
        if (data.role == "Rolü Seçiniz") {
            message.error("Rol boş geçilemez !")
            return false;
        }

        return true;
        
    }

    const handleCancel = () =>{

        setIsModalOpen(false)
        setName("");

    }


    return(
        <>

        <Button
        onClick={showModal}
        className="mt-3 bg-green-500 hover:!bg-green-300"
        icon={<PlusOutlined />}
        type="primary"
        >
            Kullanıcı Ekle
        </Button>

        <Modal title="Kullanıcı Ekle" 
            okText="Kaydet"
            cancelText="İptal" 
            maskClosable={false} 
            okButtonProps={{ className: 'bg-green-500 text-white hover:!bg-green-300' }}  
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div className='mt-5'>
                <Input className='mt-3 h-[40px]' placeholder="Adı" value={name} onChange={e=>{setName(e.target.value)}} />
                <Input className='mt-3 h-[40px]' placeholder="Soyadı" value={surname} onChange={e=>{setSurname(e.target.value)}} />
                <Input className='mt-3 h-[40px]' placeholder="Kullanıcı adı" value={username} onChange={e=>{setUsername(e.target.value)}} />
                <Input className='mt-3 h-[40px]' placeholder="Mail" type='email' value={mail} onChange={e=>{setMail(e.target.value)}} />

                <Select
                    placeholder="Cinsiyeti Seçiniz"
                    value={gender}
                    onChange={e => setGender(e)}
                    className='w-full mt-3 h-[40px]'
                    options={[
                        { value: 'E', label: 'Erkek' },
                        { value: 'K', label: 'Kız' },
                    ]}
                    />
                
                <Select
                    value={role}
                    onChange={e => setRole(e)}
                    placeholder="Rolü Seçiniz"
                    className='w-full mt-3 h-[40px]'
                    options={[
                        { value: '0', label: 'Yönetici' },
                        { value: '1', label: 'Çalışan' },
                    ]}
                    />
            </div>
        </Modal>
        
        </>
    );
}

export default AddUserModal;