import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PanelPageTitle =(props:any)=>{
    const bgColor = props.bg || "bg-gray-700";
    return(
        <>
            <div className={`${bgColor} text-white rounded-lg p-3 text-center font-bold text-[20px] flex items-center justify-center gap-3`}>
                <FontAwesomeIcon icon={props.icon} size="2x" color="#fffff" />
                {props.title}
            </div>
        </>
    );
    
}

export default PanelPageTitle;

