import React from 'react';
import ReactDOM from 'react-dom/client';
import {  BrowserRouter as Router,useRoutes } from 'react-router-dom';
import Routes from './routes/Routes';
import './index.css';

function AppRoutes() {
  return useRoutes(Routes);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <AppRoutes />
  </Router>
);

