import CustomerLayout from "../layouts/customerLayout/CustomerLayout";
import Home from "../pages/customer/Home";

const CustomerRoutes = [
    {
        path: '',
        element: <CustomerLayout />,
        children: [
            { path: '', element: <Home /> },
            { path: 'home', element: <Home /> },
        ],
    },
];

export default CustomerRoutes;