import { Spin } from 'antd';

const Loading = () =>{
    return(
        <> 
            <div className=' flex justify-center p-4' >
                <Spin size="large" fullscreen  />
            </div>
        </>
    );
}

export default Loading;