import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SearchOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { faFontAwesome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Button , Tooltip, Popconfirm, message} from "antd";
import HeadWordService from "../../../../services/HeadWordService";
import { useNavigate } from "react-router-dom";

const HeadWordTable = (props:any) =>{

    const _headWordService = HeadWordService();
    const navigate = useNavigate();

    const deleteHeadWord= async(headWordId:string)=>{

        try {

            var data={
                headWordId:headWordId
            }

            var response = await _headWordService.deleteHeadWord(data);
            console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Madde Başı silindi")

                props.onHeadWordDeleted();
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
            
        } catch (err) {
            console.log("delete head word",err)
        }
    }


    const columns = [
        {
            title: '#',
            width: 150,
            dataIndex: 'key',
            render: (text: any) => <span className="font-bold">{text}</span>,
        },
        {
            title: 'Madde Başı',
            dataIndex: 'name',
        },
        {
            title: 'Eş Sesli Kodu',
            dataIndex: 'homophoneCode',
        },
        {
            title: '',
            width: 350,
            dataIndex: 'action',
            render: (action: { id: string; name: string }) => (
                <>

                    <Button type="primary" 
                    onClick={e=>navigate(`/manager/update-head-word/${action.id}`)}
                    className="bg-yellow-500 hover:!bg-yellow-400">Güncelle</Button>
                    
                    <Button type="primary" 
                     onClick={e=>navigate(`/manager/meaning/${action.id}`)}
                    className="bg-[#36786c] hover:!bg-[#285050] mr-5 ml-5">Anlam Ekle</Button>

                    <Popconfirm
                        title="Madde başını silmek istiyor musunuz?"
                        okText="Sil"
                        onConfirm={e=>deleteHeadWord(action.id)}
                        cancelText="İptal"
                    >
                        <Button type="primary" className="bg-red-500 hover:!bg-red-400">Sil</Button>
                    </Popconfirm>

                   
                </>
                    
            ),
        },

        
    ];

    return(
        <>
            <Table dataSource={props.dataSource} columns={columns} locale={{ emptyText: <span className="text-red-400 font-bold">Madde başı bulunamadı !</span>}}  />
        </>
    );

}

export default HeadWordTable;