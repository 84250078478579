import { Alert } from "antd";
import PanelPageTitle from "../../components/PanelPageTitle";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

const Dashboard = ()=>{
    return(
        <>
            <div>
                <PanelPageTitle icon={faChartLine} bg="bg-[#3498DB]" title="Dashboard"/>


                <Alert className="mt-4" message="Çok yakında..." type="info" />
            </div>
        </>
    );

}

export default Dashboard;
