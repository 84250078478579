import { faFileInvoice, faLinesLeaning } from "@fortawesome/free-solid-svg-icons";
import PanelPageTitle from "../../../components/PanelPageTitle";
import { Breadcrumb, Button } from "antd";
import { Link, useParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import MeaningTable from "./components/MeaningTable";



const Meaning = () =>{

    const { headWordId } = useParams();

    return(
        <>
            <PanelPageTitle icon={faFileInvoice} bg="bg-[#3498DB]" title="Anlam Listesi"/>

            <Breadcrumb
                    className="mt-4"
                    items={[
                        {
                            title: <a href="/manager/head-word">Madde Başı Listesi</a>,
                        },
                        {
                            title: 'Anlam Listesi',
                        },
                    ]}
                />

            <Link to={"/manager/add-meaning/"+headWordId}>
                <Button type="primary"  className="bg-green-500 hover:!bg-green-400 mt-4" icon={<PlusOutlined />} >
                    Anlam Ekle
                </Button>
            </Link>

            <br />
            <br />

            <MeaningTable />

        </>
    );
}
export default Meaning;