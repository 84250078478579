import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SearchOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { faFontAwesome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Button , Tooltip, Popconfirm, message} from "antd";
import HeadWordService from "../../../../services/HeadWordService";
import { useNavigate } from "react-router-dom";

const MeaningTable = (props:any) =>{

    const _headWordService = HeadWordService();
    const navigate = useNavigate();

    const columns = [
        {
            title: '#',
            width: 150,
            dataIndex: 'key',
            render: (text: any) => <span className="font-bold">{text}</span>,
        },
        {
            title: 'Madde Başı',
            dataIndex: 'name',
        },
        {
            title: 'Eş Sesli Kodu',
            dataIndex: 'homophoneCode',
        },
        {
            title: 'Anlam Sırası',
            dataIndex: 'homophoneCode',
        },
        {
            title: 'Sözcük Türü',
            dataIndex: 'homophoneCode',
        },
        {
            title: 'Dil Seviyesi',
            dataIndex: 'homophoneCode',
        },
        {
            title: '',
            width: 150,
            dataIndex: 'action',
            render: (action: { id: string; name: string }) => (
                <>

                    <Button type="primary" 
                    onClick={e=>navigate(`/manager/update-head-word/${action.id}`)}
                    className="bg-yellow-500 hover:!bg-yellow-400">Güncelle</Button>
                    
                    <Button type="primary" 
                     onClick={e=>navigate(`/manager/meaning/${action.id}`)}
                    className="bg-[#36786c] hover:!bg-[#285050] mr-5 ml-5">Anlam Ekle</Button>

                    <Popconfirm
                        title="Madde başını silmek istiyor musunuz?"
                        okText="Sil"
                        cancelText="İptal"
                    >
                        <Button type="primary" className="bg-red-500 hover:!bg-red-400">Sil</Button>
                    </Popconfirm>

                   
                </>
                    
            ),
        },

        
    ];

    return(
        <>
            <Table dataSource={props.dataSource} columns={columns} locale={{ emptyText: <span className="text-red-400 font-bold">Anlam bulunamadı !</span>}}  />
        </>
    );

}

export default MeaningTable;