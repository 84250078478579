import { Breadcrumb, Button, Input, List, message, Popconfirm, Select, Upload, UploadFile, UploadProps } from "antd";
import PanelPageTitle from "../../../components/PanelPageTitle";
import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill'in varsayılan stil dosyası
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TematicService from "../../../services/TematicService";
import Loading from "../../../components/Loading";
import { faCirclePlus, faFileCirclePlus, faFilePen } from "@fortawesome/free-solid-svg-icons";
import { IAddHeadWord } from "../../../models/headWord/IAddHeadWord";
import HeadWordService from "../../../services/HeadWordService";
import { IHeadWordIdRequest } from "../../../models/headWord/IHeadWordIdRequest";
import { IUpdateHeadWord } from "../../../models/headWord/IUpdateHeadWord";


const UpdateHeadWord = () =>{

    const { headWordId } = useParams();

    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [homophoneCode, setHomophoneCode] = useState("Seçiniz");
    const [tematic, setTematic] = useState("Seçiniz");
    const [addedTematic, setAddedTematic] = useState<{ tematicId: string; name: string }[]>([]);
    const [dataTematic, setDataTematic] = useState<{ tematicId: string; name: string; orderCode?: string | null;  subOrderCode?: string | null; }[]>([]);
    const [dictionaryFormat, setDictionaryFormat] = useState("");
    const [wordFamily, setWordFamily] = useState("");
    const [addedWordFamily, setAddedWordFamily] = useState<string[]>([]);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const _tematicService = TematicService();
    const _headWordService = HeadWordService();
    const [isLoadingTematicList, setIsLoadingTematicList]= useState(false)
    const [isLoadingGetHeadWord, setIsLoadingGetHeadWord]= useState(false)

    useEffect(() =>{
        
        getTematic();              
        
    }, []) 
    
    useEffect(() => {

        getByIdHeadWord()    

    }, [dataTematic]) 

    const propsUpload: UploadProps = {
        onChange: ({ file, fileList }) => {
            setFileList(fileList); // Yeni dosya listesini güncelle
        },
        onRemove: (file) => {
            setFileList((prev) => prev.filter((item) => item.uid !== file.uid)); // Dosyayı kaldır
        },
        beforeUpload: (file) => {
            return false; // Yüklemeyi durdur
        },
        fileList,
    };

    const getByIdHeadWord = async() =>{
        try {
            setIsLoadingGetHeadWord(true);
            const data = {
                headWordId : headWordId!
            }
            var response = await _headWordService.getByIdHeadWord(data);
            console.log('response: ', response);

            if (response.statusCode == 200 ) {

                if (response.data != null) {
                    setName(response.data.name)
                    setHomophoneCode(response.data.homophoneCode)
                    setDictionaryFormat(response.data.dictionaryFormat);
                    setAddedWordFamily(response.data.wordFamily)
                    response.data.tematicId.forEach((item:string) => {
                        //console.log('item: ', item);

                        addTematic(item,true)
                        
                    });
                }
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log('err: ', err);
        }
        finally{
            setIsLoadingGetHeadWord(false);
        }
    }
    const getTematic = async() =>{
        try {
            setIsLoadingTematicList(true);
            var response = await _tematicService.getTematic();
            //console.log('response: ', response);

            if (response.statusCode == 200 ) {

                setDataTematic(response.data);                
                

            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log('err: ', err);
        }
        finally{
            setIsLoadingTematicList(false);
        }
    }

    const handleOk = async () =>{
        try {

            if(validatorHeadWord() == false)
            {
                return;
            }


            var data:IUpdateHeadWord ={
                tematicId: addedTematic.map(x=>x.tematicId),
                wordFamily: addedWordFamily,
                headWord:{
                    headWordId:headWordId,
                    name:name.trim(),
                    homophoneCode:homophoneCode,
                    dictionaryFormat:dictionaryFormat,
                    soundUrl:"--",
                }   
            }
            console.log('data: ', data);
            var response = await _headWordService.updateHeadWord(data);
               console.log('response: ', response);
            if (response.statusCode == 200) {
                message.success("Madde Başı Güncellendi")
                navigate("/manager/head-word")
                
            }
            response.errors.forEach((i:any) => {                
                message.error(i)
            });  
            
        } catch (err) {
            console.log("UpdateHeadWord",err)
        }


    }

    const validatorHeadWord = () =>{
        console.log('dictionaryFormat: ', dictionaryFormat);
        if (name=="") {
            message.error("Madde başı boş geçilemez")
            return false;
        }
        if (homophoneCode=="Seçiniz") {
            message.error("Eş sesli kodu boş geçilemez")
            return false;
        }

        return true;
    }

    const addTematic = (tematicId:string,onInit : boolean = false) =>{
        //console.log('onInit: ', onInit);
        //console.log('tematicId: ', tematicId);

        if (onInit == false) {
            
            if (tematic == "Seçiniz" || tematicId =="") {
                 return message.error("Lütfen tematik seçiniz !");
             } 
        }
                
        
        //console.log('dataTematic: ', dataTematic);
        var foundTematic = dataTematic.find(x=>x.tematicId == tematicId)
        console.log('foundTematic: ', foundTematic);
        if (foundTematic) {

            const isUserAssignment = addedTematic.findIndex(x=>x.tematicId== tematicId) 
            
            if (isUserAssignment != -1) {
                return message.error("Tematik zaten eklendi !");
                
            }
            const data = {
                tematicId: foundTematic.tematicId,
                name : foundTematic.name
            }
            setAddedTematic(e =>[...e,data])
            console.log(addedTematic)            
            setTematic("Seçiniz");
            
        }
        
    }

    const addWordFamily = (sentence: string) => {
        const trimmedSentence = sentence.trim();
    
        if (tematic == "") {
            return message.error("Boş eklenemez!");
        }
    
        if (trimmedSentence === "") {
            return message.error("Sadece boşluk eklenemez!");
        }
    
        setAddedWordFamily((prev) => {
            // Eğer sentence zaten listede varsa ekleme yapma
            if (prev.includes(trimmedSentence)) {
                message.warning("Bu kelime zaten eklendi!");
                return prev; // Mevcut listeyi döndür
            }
            
            // Yeni kelimeyi ekle
            return [...prev, trimmedSentence];
        });
    
        setWordFamily("");
    };
    
    


    const deleteWordFamily = (sentence: string) => {
        setAddedWordFamily(e => e.filter(x => x !== sentence));
        message.info('Tematik silindi!');
    };

    const deleteTematic = (tematicId:string) => {
        
        var foundUser = addedTematic.find(x=>x.tematicId==tematicId)
        
        if (foundUser) {
            setAddedTematic(e=>e.filter(user => user.tematicId !== tematicId))
            message.info('Tematik silindi !');
        }
        

    }

    return(
        <>
            <PanelPageTitle icon={faFilePen} bg="bg-[#3498DB]" title="Madde Başı Güncelle"/>

            <Breadcrumb
                    className="mt-4"
                    items={[
                        {
                            title: <a href="/manager/head-word">Madde Başı Listesi</a>,
                        },
                        {
                            title: 'Madde Başı Güncelle',
                        },
                    ]}
                />

            {
                isLoadingTematicList == true || isLoadingGetHeadWord == true  ?
                (<Loading/>):
                (
                    <div className="mt-5">

                    <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                        <span className="text-[20px] font-bold">Madde Başı : </span>
                        <Input className='mt-3 h-[40px] text-[20px]' value={name} onChange={e=>setName(e.target.value)} placeholder="Örneğin : beraber"  />
                    </div>
    
                    <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                        <span className="text-[20px] font-bold">Eş Sesli Kodu : </span>
                        <Select
                        value={homophoneCode}
                        onChange={e=>setHomophoneCode(e)}
                        placeholder="Seçiniz"
                        className='w-full mt-3 h-[40px] text-[20px]'
                        options={[
                            { value: 'I', label: 'I' },
                            { value: 'II', label: 'II' },
                            { value: 'III', label: 'III' },
                            { value: 'T', label: 'T' },
                        ]}
                        />
                    </div>
    
                    <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                        <span className="text-[20px] font-bold">Tematik Seçimi : </span>
                        <div className="flex items-center mt-3 gap-2 text-[20px]">
                        <Select
                                placeholder="Tematik Seçiniz"
                                className="w-full h-[40px]"
                                value={tematic}
                                onChange={(e) => setTematic(e)}
                            >
                                {dataTematic.map((option, index) => {
                                    // Bir üst seviye orderCode'u bul
                                    const parentOrderCode = option.orderCode
                                        ? option.orderCode // orderCode mevcutsa bunu kullan
                                        : dataTematic.find(
                                            (parent) => parent.subOrderCode === option.subOrderCode
                                        )?.orderCode || "> "; // Yoksa subOrderCode eşleşen üst öğeyi ara

                                    // Hiyerarşik yapı oluştur
                                    const hierarchyPrefix = parentOrderCode
                                        ? `${parentOrderCode}${option.subOrderCode ? `${option.subOrderCode}` : ""}.`
                                        : "";

                                    return (
                                        <Select.Option key={index} value={option.tematicId}>
                                            {hierarchyPrefix && <b>{hierarchyPrefix} </b>}
                                            {option.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
    
                            <Button type="primary"  
                            onClick={e=>addTematic(tematic)}
                            className="bg-[#3498DB] hover:opacity-70 h-[40px] !w-[40px]" icon={<PlusOutlined />} />
                                
                        </div>

                        {
                            addedTematic.length != 0 ?
                            (
                                <div className="mt-4">
                                <List
                                bordered
                                dataSource={addedTematic}
                                renderItem={(item) => (
                                    <List.Item>
                                        <div className="flex justify-between items-center  w-full">
    
                                            <span>{item.name}</span>
    
    
                                            <Popconfirm
                                                title="Tematiği silmek istiyor musunuz ?"                                                    
                                                okText="Sil"
                                                cancelText="İptal"
                                                onConfirm={() => deleteTematic(item.tematicId)}
                                            >
                                                <Button
                                                type="primary"
                                                className="bg-red-700 hover:!bg-red-500" 
                                                icon={<DeleteOutlined />} 
                                                >
                                                </Button>
                                            </Popconfirm>
    
                                            
                                        </div>
    
                                    </List.Item>
                                )}
                                locale={{
                                    emptyText: <span className="text-red-400 font-bold">Tematik eklenmedi !</span>
                                }}
                                />
    
                            </div>

                            ):(
                                <>
                                </>
                            )
                        }

                       
    
                    </div>
    
                    <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                        <span className="text-[20px] font-bold">Sözcük Biçimi :</span>
                        <ReactQuill
                        className="mt-3 h-[200px] mb-[40px] !rounded-lg bg-white"
                        placeholder="Metninizi buraya yazın..."
                        value={dictionaryFormat}
    
                        onChange={e=>setDictionaryFormat(e)}
                        modules={{
                        toolbar: [
                            ["bold", "italic", "underline"], // Kalın, italik, altı çizili ve üstü çizili
                            ["link"],
                        ],
                        }}
                        formats={[
                        "bold",
                        "italic",
                        "underline",
                        "link",
                        ]}
                        />
                    </div>
    
                    <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                        <span className="text-[20px] font-bold">Ses Dosyası :</span>
                        <br />
                        <Upload {...propsUpload} maxCount={1}>
                            <Button icon={<UploadOutlined />}>Ses Dosyası Ekleyin</Button>
                        </Upload>
                    </div>

                    
    
                    <div className="bg-[#FDFDF8] p-7 rounded-lg mb-3">
                        <span className="text-[20px] font-bold">Söz Ailesi :</span>
                        <div className="flex gap-2 items-center">
    
                            <ReactQuill
                            className="mt-3 w-full h-[100px] mb-[40px] !rounded-lg bg-white"
                            placeholder="Metninizi buraya yazın..."
                            value={wordFamily}
                            onChange={e=>setWordFamily(e)}
                            modules={{
                            toolbar: [
                               
                                ["bold", "italic", "underline"], // Kalın, italik, altı çizili ve üstü çizili
                                ["link"],
                            ],
                            }}
                            formats={[
                           
                            "bold",
                            "italic",
                            "underline",
                            "link",
                            ]}
                            />  
    
                            <Button type="primary"  
                            onClick={e=>addWordFamily(wordFamily)}
                            className="bg-[#3498DB] hover:!bg-opacity-70 mt-14 h-[100px] !w-[40px]" icon={<PlusOutlined />} />
    
                        </div>
    
                        <div className="mt-5">
                            {
                                addedWordFamily.length != 0 ?
                                (
                                    <List
                                
                                    bordered
                                    dataSource={addedWordFamily}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <div className="flex justify-between items-center  w-full">
    
                                                <div dangerouslySetInnerHTML={{ __html: item }} />
    
    
                                                <Popconfirm
                                                    title="Söz ailesini silmek istiyor musunuz ?"                                                    
                                                    okText="Sil"
                                                    cancelText="İptal"
                                                    onConfirm={() => deleteWordFamily(item)}
                                                >
                                                    <Button
                                                    type="primary"
                                                    className="bg-red-700 hover:!bg-red-500" 
                                                    icon={<DeleteOutlined />} 
                                                    >
                                                    </Button>
                                                </Popconfirm>
    
                                                
                                            </div>
    
                                        </List.Item>
                                    )}
                                    locale={{
                                        emptyText: <span className="text-red-400 font-bold">Boş !</span>
                                    }}
                                    />
    
                                ):
                                (
                                    <>
                                    </>
                                )
                            }
    
                            
    
                        </div>
    
                       
    
                        
                    </div>
    
                    
    
                    <div className="p-7 mb-3 flex justify-end  gap-3">

                        <Button type="primary" onClick={e=>navigate("/manager/head-word")}  className="bg-red-500 hover:!bg-red-400 h-[40px]"  >
                            İptal
                        </Button>

                        <Button type="primary" onClick={handleOk}  className="bg-yellow-500 hover:!bg-yellow-400 h-[40px]"  >
                            Güncelle
                        </Button>
                        
                    </div>
    
    
    
    
    
                    
    
                </div>

                )
            }

           
        </>
    );
}

export default UpdateHeadWord;