import { Alert, message } from "antd";
import { faChartLine, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import PanelPageTitle from "../../../components/PanelPageTitle";
import TematicTable from "./components/TematicTable";
import { useEffect, useState } from "react";
import TematicService from "../../../services/TematicService";
import Loading from "../../../components/Loading";
import AddTematicModal from "./components/AddTematicModal";

const Tematic = ()=>{

    const [dataSource, setDataSource] = useState([]);
    const _tematicService = TematicService();
    const [isLoadingTematicList, setIsLoadingTematicList]= useState(false)

    useEffect(() =>{
        getTematic();        
    }, []) //? Boş dizi yalnızca ilk yüklendiğinde derlenir

   

    
    const getTematic = async ()=>{
        try { 

            setIsLoadingTematicList(true);
            var response = await _tematicService.getParentTematic();
            
            if (response.statusCode == 200 ) {
                const formattedData = response.data.map((item:any, index:any) => ({
                    key: index + 1, 
                    action:{
                        id: item.tematicId,
                        name: item.name,
                    },
                    name: item.name,
                    orderCode:item.orderCode,
                    
                }));
                console.log('response: ', response);
                
                setDataSource(formattedData);
            }
            
            response.errors.forEach((i:any) => {                
                message.error(i)
            });
        } catch (err) {
            console.log("getTematic :", err);
        }finally{
            setIsLoadingTematicList(false);

        }
        
    }
    
    return(
        <>
            <div>
                <PanelPageTitle icon={faPuzzlePiece} bg="bg-[#3498DB]" title="Tematik Listesi"/>

                <AddTematicModal onTematicAdded={getTematic}/>
                {
                    isLoadingTematicList == true ?
                    <Loading /> : 
                    <div className="mt-5">
                        <TematicTable dataSource={dataSource} />
                    </div>
                }
            </div>
        </>
    );

}

export default Tematic;
