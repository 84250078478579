import axios from '../interceptors/AuthInterceptor';
import { IAddSubTematic } from '../models/tematic/IAddSubTematic';
import { IAddTematic } from '../models/tematic/IAddTematic';
import { IAddUser } from '../models/user/IAddUser';

const TematicService =()=> {

    const apiUrl = process.env.REACT_APP_API_URL


    const getTematic = async () => {  
        try { 
            
            const response = await axios.get(`${apiUrl}/Tematic/get-tematic`,);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const addTematic = async (data:IAddTematic) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/Tematic/add-tematic`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const addSubTematic = async (data:IAddSubTematic) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/Tematic/add-sub-tematic`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };

    const getParentTematic = async () => {  
        try { 
            
            const response = await axios.get(`${apiUrl}/Tematic/get-parent-tematic`,);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };
    const getSubTematic = async (data:any) => {  
        try { 
            
            const response = await axios.post(`${apiUrl}/Tematic/get-sub-tematic`,data);
            return response.data;
        } 
        catch (error: any) {
            return error.response.data;
        }
    };


    return {
        getTematic,
        getParentTematic,
        addTematic,
        addSubTematic,
        getSubTematic
    };


}

export default TematicService;
