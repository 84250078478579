import ManagerLayout from "../layouts/managerLayout/ManagerLayout";
import Dashboard from "../pages/manager/Dashboard";
import AddHeadWord from "../pages/manager/headWord/AddHeadWord";
import HeadWord from "../pages/manager/headWord/HeadWord";
import UpdateHeadWord from "../pages/manager/headWord/UpdateHeadWord";
import AddMeaning from "../pages/manager/meaning/AddMeaning";
import Meaning from "../pages/manager/meaning/Meaning";
import Tematic from "../pages/manager/tematic/Tematic";
import User from "../pages/manager/user/User";

const ManagerRoutes = [
    {
        path: 'manager',
        element: <ManagerLayout />,
        children: [
            { path: 'dashboard', element: <Dashboard /> },
            { path: 'user', element: <User /> },
            { path: 'head-word', element: <HeadWord /> },
            { path: 'tematic', element: <Tematic /> },
            { path: 'add-head-word', element: <AddHeadWord /> },
            { path: 'update-head-word/:headWordId', element: <UpdateHeadWord /> },
            { path: 'meaning/:headWordId', element: <Meaning /> },
            { path: 'add-meaning/:headWordId', element: <AddMeaning /> },
        ],
    },
];

export default ManagerRoutes;